body {
  background-image: url("../assets/background-image.png");
  background-repeat: no-repeat;
  background-size: cover;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.image-component {
}

.image-to-download {
  img {
    width: 100vh;
    height: 100vh;
    cursor: pointer;
  }
}

/******************************************** Home **********************************************/
.home {
  height: 100vh;
  //padding: 20px 20px;

  .home-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 60px;
    //padding-bottom: 10px;
    height: 100%;
    gap: 0;

    a {

      svg {
        width: 200px;
        height: 94px;
      }
    }

    .audio-download {
      background: #F8DE4B;
      padding: 10px 100px;
      border: 3px solid #F8DE4B;
      border-radius: 14px;
      cursor: pointer;
    }
  }


  .language-button {
    position: absolute;
    right: 10px;
    top: 10px;

    button {
      margin-right: 20px;
      border: none;
      background: transparent;

      select {
        border: none;
        //color: #FFFF00;
        color: white;
        font-size: 32px;
        font-weight: 600;
        outline: none;

        &:after {
          background-color: black;
        }

        option {
          background: transparent;
        }
      }
    }
  }
}

/******************************************** end Home **********************************************/

/******************************************** Images **********************************************/

.images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .images-first-row {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    gap: 20px;

    img {
      max-width: 160px;
    }
  }

  .images-second-row {
    display: flex;
    flex-direction: row;
    gap: 20px;

    img {
      max-width: 160px;
    }
  }
}

/******************************************** end Images **********************************************/
/******************************************** ErrorPage **********************************************/

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  h1 {
    color: white;
    font-weight: 800;
    font-size: 64px;
  }

  h3 {
    color: white;
    font-size: 32px;
    font-weight: bold;
  }

  p {
    color: white;
    text-align: center;
    font-size: 24px;
  }
}

/******************************************** end ErrorPage **********************************************/